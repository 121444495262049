.container {
    width: "100%";
    height: 505px;
    margin: 50px 25px 0;
    background-color: rgba(81, 81, 81, 0.08);
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.main {
    display: flex;
    flex: 1;
    padding: 0 80px;
  
}
.mainChild {
    flex: 3;
    margin: 1px;
    flex-direction: column;
    display: flex;
    justify-content: center;
}
.second img {
    width: 100%;
}
.first {
    align-items: end;
}
.mainChildContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mainChildTitle {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #121212;
    text-shadow: 0px 0px 250px #121212;
    margin: 0 0 10px;
}
.mainChildDescription {
    font-family: 'GothamPro';
    font-size: 13px;
    line-height: 105%;
    color: #121212;
    text-shadow: 0px 0px 250px #121212;
    margin: 0;
    width: 240px;
}
.mainChildIcon {
   margin-right: 20px;
}
.mainChildContainer2 {
    margin-right: 50px;
    margin-top: 37px;
}
.mainChildContainer4 {
    margin-left: 50px;
    margin-top: 37px;
}

.third {
    margin-left: 20px;
}
@media only screen and (max-width: 1440px) {
    .main {
        padding: 0 40px;
    }
    .mainChildTitle {
        font-size: 14px;
    }
    .mainChildDescription {
        font-size: 12px;
    }
    .mainChildContainer2 {
        margin-right: 25px;
        margin-top: 30px;
    }
    .mainChildContainer4 {
        margin-left: 25px;
        margin-top: 30px;
    }
}
@media only screen and (max-width: 990px) {
    .container {
        height: auto;
        padding: 10px 0 20px;
    }
    .main {
        flex-direction: column;
        align-items: center;
    }
    .mainChild {
        align-items: center;
    }
    .mainChild img {
        width: 40%;
    }
    .mainChildContainer2 {
        margin: 0;
    }
    .third {
        margin: 0;
    }
    .mainChildContainer4 {
        margin: 0;
    }
    .mainChildContainer {
        margin: 10px 0;
    }
    .mainChildTitle {
        font-size: 16px;
    }
    .mainChildDescription {
        font-size: 13px;
    }
}
@media only screen and (min-width: 1600px) {
    .mainChild img {
        max-width: 700px;
    }
    .container {
        height: auto;
        padding: 10px 0 20px;
    }
}
@media only screen and (max-width: 768px) {
    .mainChild img {
        width: 50%;
    }
}
@media only screen and (max-width: 580px) {

    .mainChild img {
        width: 300px;
        max-width: 80%;
    }
   
}