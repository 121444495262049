
.main {
    background-color: #fff; 
    padding: 100px 80px;
}
.subTitle {
    font-family: Inter;
    font-size: 30px;
    line-height: 60px;
    color: #121212
}


@media only screen and (max-width: 768px) {
    .main {
        padding: 80px 30px;
    }
}