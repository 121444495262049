.card {
    width: 25%;
    height: calc(100vw * 0.35);
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    box-shadow: 0px 2px 15px 4px rgba(81, 81, 81, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 0px 10px 50px;
    cursor: pointer;
    text-decoration: none;
}
.title {
    width: 100%;
    font-family: Gotham Pro;
    font-weight: 500;
    font-size: 28px;
    text-align: center;
    color: #fff;
    background: rgba(232, 244, 255, 0.3);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    margin: 0;
    height: calc(100vw * 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 5px;
    box-sizing: border-box;
}
@media only screen and (max-width: 1440px) {
    .title {
        font-size: 22px;
    }
}
@media only screen and (max-width: 1200px) {
    .title {
        font-size: 20px;
        height: auto;
        min-height: calc(100vw * 0.05);
        padding: 5px 10px;
    }
}
@media only screen and (max-width: 768px) {
    .card {
        width: 40%;
        height: calc(100vw * 0.60);
    }
    .title {
        font-size: 22px;
        height: calc(100vw * 0.1);
        padding: 5px 10px;
    }
}
@media only screen and (max-width: 660px) {
    .title {
        font-size: 17px;
        height: calc(100vw * 0.1);
        padding: 5px 10px;
    }
}
@media only screen and (max-width: 480px) {
    .card {
        width: 80%;
        height: calc(100vw * 1);
    }
    .title {
        font-size: 25px;
        height: calc(100vw * 0.18);
        padding: 5px 25px;
    }
}
@media only screen and (max-width: 380px) {

    .title {
        font-size: 22px;
    }
}