.container {
    width: "100%";
    height: 560px;
    margin: 50px 25px 62px;
    background-color: rgba(81, 81, 81, 0.08);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.main {
    display: flex;
    flex: 1;
    padding: 20px 75px;
    flex-direction: column;
}
.firstRow {
    display: flex;
    height: 400px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.firstRowMain {
    padding-right: 20px;
}
.firstRow .textRow .title {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #121212;
    display: block;
}
.firstRow .textRow .description {
    display: block;
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 18px;
    line-height: 150%;
    color: #121212;
    margin-bottom: 10px;
    text-decoration: none;
}

.secondRow {
    display: flex;
    justify-content: space-between;
}
.shareContainer {
    align-self: flex-end;
}
.shareText {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #121212;
    margin-right: 5px;
}
.shareItem {
    margin-left: 10px;
    width: 34px;
    height: 34px;
}
@media only screen and (max-width: 1024px) {
    .main {
        padding: 25px 30px;
    }
}
@media only screen and (max-width: 970px) {
    .firstRow .textRow .title {
        font-size: 18px;
        line-height: 150%;
        color: #121212;
        display: block;
        
    }
    .firstRow .textRow .description {
        display: block;
        font-family: 'GothamPro';
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #121212;
        margin-bottom: 10px;
    }
}
@media only screen and (max-width: 768px) {
    .container {
        height: auto;
    }
    .main {
        padding: 30px 45px;
    }
    .firstRow {
        flex-direction: column;
        height: auto;
        margin-bottom: 10px;
    }
    .firstRowMain {
        margin-bottom: 10px;
    }
    .shareItem {
        margin-left: 5px;
        width: 24px;
        height: 24px;
    }
    .shareText {
        font-size: 12px;
    }
}
@media only screen and (max-width: 550px) {
    .container {
        height: auto;
    }
    .main {
        padding:  20px;
    }
    .firstRow .textRow .title {
        font-size: 16px;

    }
    .firstRow .textRow .description {
        font-size: 16px;
    }
}
@media only screen and (max-width: 550px) {
   .shareText {
    display: none;
   }
}


@media only screen and (max-width: 430px) {
    .secondRow {
        flex-direction: column;
        justify-content: flex-start;
    }
    .secondRow .shareContainer {
        align-self: flex-start;
        margin-top: 20px;
    }
 }