.container {
    width: "100%";
    height: 600px;
    overflow: hidden;
    position: relative;
}
.parallax {
    width: "100%";
    min-height: 700px;
    background-image: url("./assets/bg.jpg");
    background-size: cover;
}
.main {
    position: absolute;
    top: 28px;
    bottom: 20px;
    left: 75px;
    right: 75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.glass {
    display: flex;
    background: rgba(232, 244, 255, 0.3);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    width: 800px;
    height: 300px;
    justify-content: center;
    align-items: center;
    padding:  0 20px;
}
.glass h1 {
    font-family: 'DIN Condensed';
    font-weight: 700;
    font-size: 80px;
    line-height: 95%;
    letter-spacing: -0.025em;
    color: #FFFFFF;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.41);

}
.glass p {
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 24px;
    line-height: 27px;
    text-align: right;
    color: #FFFFFF;
    text-shadow: 0px 0px 80px #121212;
}
.glass2 {
    flex-direction: column;
    align-self: flex-end;
    width: 550px;
    height: 160px;
    padding: 30px 20px;
}

@media only screen and (max-width: 1200px) {
    .main {
        left: 45px;
        right: 45px;
    }
}
@media only screen and (max-width: 1024px) {
    .container {
        height: 520px;
    }
    .glass {
        width: 560px;
        height: 250px;
        padding:  0 20px;
    }
    .glass h1 {
        font-size: 60px;
        line-height: 95%;    
    }
    .glass p {
        font-size: 20px;
        line-height: 24px;
    }
    .glass2 {
        width: 440px;
        height: 140px;
        padding: 30px 20px;
    }
}
@media only screen and (max-width: 768px) {
    .main {
        left: 15px;
        right: 15px;
        top: 35px;
    }
    .container {
        height: 470px;
    }
    .glass {
        width: 380px;
        height: 200px;
        padding:  0 20px;
    }
    .glass h1 {
        font-size: 43px;
        line-height: 95%;    
    }
    .glass p {
        font-size: 18px;
        line-height: 20px;
        margin: 2px 0;
    }
    .glass2 {
        width: 350px;
        height: 120px;
        padding: 30px 20px;
    }

}
@media only screen and (max-width: 480px) {
    .main {
        left: 0;
        right: 0;
    }
    .container {
        height: 410px;
    }
    .glass {
        width: 80%;
        height: auto;
        margin: 0 auto;
        padding:  20px;
    }
    .glass h1 {
        font-size: 35px;
        line-height: 95%;  
        margin: 0;  
    }
    .glass p {
        font-size: 18px;
        line-height: 20px;
        margin: 2px 0;
    }
    .glass2 {
        width: 70%;
        height: auto;
        padding: 20px;
        margin: 0 auto;    
    }
}
@media only screen and (max-width: 420px) {
    .container {
        height: 470px;
    }
}
@media only screen and (max-width: 350px) {
    .container {
        height: 550px;
    }
}