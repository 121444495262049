.title {
    font-family: "GothamPro";
    font-weight: 500;
    font-size: 36px;
    line-height: 34px;
    color: #121212;
    text-transform: uppercase;
  }
  .description {
    font-family: "GothamPro";
    font-weight: 300;
    font-size: 28px;
    line-height: 105.2%;
    color: #121212;
}
.showMore {
    display: flex;
    font-family: "GothamPro";
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #9B2743;
    text-decoration: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .showMore svg {
    position: relative;
    top: -1px;
    margin-left: 5px;
  }
  .part { 
    width: 49%;
   }
@media only screen and (min-width: 1600px) {
    .title {
        font-size: 40px;
        line-height: 38px;
      }
      .description {
        font-size: 32px;
    }
}
@media only screen and (max-width: 1200px) {
    .title {
        font-size: 30px;
        line-height: 28px;
      }
      .description {
        font-size: 22px;
    }
}
@media only screen and (max-width: 1024px) {
    .title {
        font-size: 24px;
        line-height: 20px;
    }
    .description {
        font-size: 16px;
    }
    .main {
        align-items: center;
    }
    .showMore {
        font-size: 16px;
        line-height: 17px;
    }
    .showMore svg {
        width: 50px;
        height: 12px;
    }
}
@media only screen and (max-width: 480px) {
    .main {
        flex-direction: column!important;
    }
    .part {
        width: 100%;
        margin-top: 20px;
    }
}