.title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    color: #121212;
}
.subTitle {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #121212;
   
}
.form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 700px;
    margin-top: 20px;
}
.input {
    background: rgba(217, 217, 217, 0.5);
    width: 250px;
    height: 60px;
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 300;
    font-size: 18.7059px;
    line-height: 18px;
    letter-spacing: -0.05em;
    color: #414141;
    outline: none;
    border: none;
    margin-bottom: 25px;
    padding: 0 10px;
    box-sizing: border-box;
}
.longInput {
    width: 400px;
    margin-right: 20px;
}
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.shareContainer {
    align-self: flex-end;
}
.shareText {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    text-align: center;
    letter-spacing: -0.05em;
    color: #121212;
    margin-right: 15px;
}
.btn {
    width: 250px;
}
.shareText2 {
    display: none;
}
@media only screen and (max-width: 1100px) {
    .title {
        font-size: 30px;
        line-height: 38px;
    }
    .subTitle {
        font-size: 16px;
        line-height: 15px;
    }
    .form {
        width: 540px;
    }
    .input {
        width: 180px;
        height: 60px;
    }
    .longInput {
        width: 340px;
        margin-right: 20px;
    }
    .btn {
        width: 180px;
    }
}
@media only screen and (max-width: 920px) {
    .title {
        line-height: 30px;
    }
    .form {
        width: 480px;
    }
    .input {
        width: 160px;
        height: 60px;
    }
    .longInput {
        width: 300px;
        margin-right: 20px;
    }
    .btn {
        width: 160px;
    }
}
@media only screen and (max-width: 860px) {
    .row {
        display: flex;
        flex-direction: column;
    }
    .shareContainer {
        align-self: flex-start;
        margin-top: 40px;
    }
    .form {
        width: 100%;
    }
    .input {
        width: 35%;
    }
    .longInput {
        width: 60%;
    }
    .btn {
        width: 35%;
    }
    .shareText {
        display: none;
    }
    .shareText2 {
        display: inline;
        margin-left: 15px;
    }
}
@media only screen and (max-width: 600px) {
    .title {
        font-size: 26px;
        line-height: 28px;
    }
    .form {
        width: 100%;
    }
    .input {
        width: 40%;
        margin-bottom: 10px;
    }
    .longInput {
        width: 55%;
        margin-right: 10px;
    }
    .btn {
        width: 40%;
    }
}
@media only screen and (max-width: 480px) {
    .form {
        width: 100%;
        flex-direction: column;
    }
    .input {
        width: 100%;
        margin-bottom: 10px;
    }
    .longInput {
        width: 100%;
        margin-right: 10px;
    }
    .btn {
        width: 100%;
        margin-top: 15px;
    }
}