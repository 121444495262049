:root {
    --color-bordo: rgba(155, 39, 67, 1);
    --color-gray: "rgba(58, 67, 78, 1)";
    --color-black: "rgba(18, 18, 18, 1)";
    --color-white: "rgba(255, 255, 255, 1)";
}
.container {
    background-color: #fff;
    height: 70px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    display: flex;
    box-shadow: 0px 0px 60px rgba(65, 65, 65, 0.6);
    align-items: center;
    justify-content: space-between;
    position: absolute;
    z-index: 999;
    
}
.logo {
    display: flex;
    text-decoration: none;
    color: var(--color-black);
    align-items: center;
}
.logo svg {
    width: 30px;
    height: 36px;
}
.logoText {
    font-family: 'DinBold';
    line-height: 36px;
    letter-spacing: -0.02em;
    margin-left: 4px;
    color:#121212;
    position: relative;
    bottom: 3.5px;
    font-size: 28px;
    padding-top: 20px;
}
.menu {
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: #fff;
    top: 70px;
    margin: 0;
    box-sizing: border-box;
    padding: 0 30px; 
    border-top: 1px solid rgb(155, 39, 67);
    z-index: 999;
    transform: translate();
    transition: all 200ms ease;
    overflow: hidden;
    height: auto;
}
.menuItem {
    text-transform: uppercase;
    text-decoration: none;
    font-family: "GothamPro";
    letter-spacing: -0.05em;
    color: var(--color-black);
    font-size: 20px;
    margin: 10px;
}

.menuItem .line {
    display: flex;
    height: 1px;
    width: 0%;
    background-color: var(--color-bordo);
    margin: 0 auto;
    transition: width 200ms;
}
.menuItem:hover .line {
    width: 80%;
}
.menuLi {
    list-style: none;
    display: flex;
}
.activeMenu  .line {
    width: 80%;
}
.headerRight {
    display: flex;
}
.phoneItem {
    text-decoration: none;
    font-family: 'GothamPro';
    letter-spacing: -0.055em;
    color: var(--color-black);
    font-size: 14px;
    line-height: 14px;
    margin-left: 10px;
}
.phoneContainer {
    display: flex;
    flex-direction: row;
}
.menuLi:hover .dropDown {
    max-height: 1000px;
    opacity: 1;
    transform: scale(1);
    border:  1px solid var(--color-bordo);
}
.dropDown {
    position: absolute;
    top: 70px;
    background-color: #fff;
    z-index: 99;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    max-height: 0;
    border:  0;
    opacity: 1;
    box-sizing: border-box;
    transition: all 0.5s, border 0s;
    box-shadow: -14px 15px 30px 1px rgba(0,0,0,0.31);
}
.dropDown .menuItem {
    margin: 20px ;
    text-align: start;
}

