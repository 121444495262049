

@font-face {
    font-family: 'GothamPro';
    src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro.ttf) format('ttf');
  }
  @font-face {
    font-family: 'GothamProBold';
    src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_bold.ttf) format('ttf');
  }
  @font-face {
    font-family: 'GothamProBlack';
    src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_black.ttf) format('ttf');
  }
  @font-face {
    font-family: 'GothamProMedium';
    src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_medium.ttf) format('ttf');
  }
  @font-face {
    font-family: 'GothamProLight';
    src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_light.ttf) format('ttf');
  }
  @font-face {
    font-family: 'DinBold';
    src: local('DIN Condensed Bold'), url(./assets/fonts/DinCondensed/DINCondensedBold.ttf) format('ttf');
  }
  @font-face {
    font-family: 'Inter';
    src: local('Inter'), url(./assets/fonts/Inter/Inter.ttf) format('ttf');
  }