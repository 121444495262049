

.lg-react-element {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.lg-react-element a {
    box-sizing: border-box;
    max-width: 25%;
    flex: 25%;
    padding: 0 4px;
}
 .lg-react-element .imgStyle {
    width: 100%;
    height: 220px;
    object-fit: cover;
    vertical-align: middle;
    margin: 4px;
    align-self: center;
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .lg-react-element a {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .lg-react-element a {
      flex: 100%;
      max-width: 100%;
    }
  }