@font-face {
  font-family: 'GothamPro';
  src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro.ttf) format('ttf');
}
@font-face {
  font-family: 'GothamProBold';
  src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_bold.ttf) format('ttf');
}
@font-face {
  font-family: 'GothamProBlack';
  src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_black.ttf) format('ttf');
}
@font-face {
  font-family: 'GothamProMedium';
  src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_medium.ttf) format('ttf');
}
@font-face {
  font-family: 'GothamProLight';
  src: local('GothamPro'), url(./assets/fonts/GothamPro/gothampro_light.ttf) format('ttf');
}
@font-face {
  font-family: 'DinBold';
  src: local('DIN Condensed Bold'), url(./assets/fonts/DinCondensed/DINCondensedBold.ttf) format('ttf');
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter/Inter.ttf) format('ttf');
}




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: "#121212"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.top_sales .swiper-wrapper {
  display: flex;
  padding-left: 50px;
}

.top_sales .swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 220px!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.galery .swiper-wrapper {
  display: flex;
  /* padding-left: 50px; */
}

.galery .swiper-wrapper .swiper-slide {
  width: 300px!important;
  display: flex;
  justify-content: center;
  align-items: center;
}
