.sliderContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
}
.sliderItem {
    width: 100%;
    height: 100%;
}
.sliderItem .img {
    object-fit: cover;
}
.sliderItem .glass {
    position: absolute;
    top: 0;
    left: 0;
    width: 710px;
    background: rgba(232, 244, 255, 0.3);
    box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.sliderItem .glass .title {
    font-family: 'GothamPro';
    font-weight: 900;
    font-size: 70px;
    line-height: 105.2%;
    letter-spacing: -0.05em;
    color: var(--color-black);
    width: 550px;
    margin: 0;
}
.sliderItem .glass .subTitle {
    font-family: 'GothamPro';
    font-size: 28px;
    width: 550px;
    line-height: 34px;
    letter-spacing: -0.05em;
    color: var(--color-black);
    margin-top: 0px;
    font-weight: 300;
}
.control-dots {
    width: 710px!important;
}
.btnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media only screen and (max-width: 768px) {
    .sliderItem .glass {
        width: 60%;
        padding-left: 30px;
    }
    .sliderItem .glass .title {
        font-size: 50px;
        width: 90%;
    }
    .sliderItem .glass .subTitle {
        font-size: 20px;
        width: 90%;
        line-height: 105%;
        margin-top: 10px;
    }
    .control-dots {
        width: 60%!important;
    }
    .btnsContainer {
        flex-direction: column;
        align-items: start; 
    }
}
@media only screen and (max-width: 600px) {
    .sliderItem .glass {
        width: 70%;
    }
    .sliderItem .glass .title {
        font-size: 40px;
    }
    .sliderItem .glass .subTitle {
        font-size: 18px;
    }
}
@media only screen and (max-width: 480px) {
    .sliderItem .glass {
        justify-content: flex-start;
        padding-top: 10%;
    }
    .sliderItem .glass {
        width: 80%;
    }
    .sliderItem .glass .title {
        font-size: 35px;
    }
    .sliderItem .glass .subTitle {
        font-size: 17px;
    }
}
@media only screen and (max-width: 480px) {
    .sliderItem .glass .title {
        font-size: 30px;
    }

}