
.main {
    background-color: #fff; 
    padding: 100px;
}
.subTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-decoration-line: underline;
    color: "#121212"
}
.text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: "#121212"
}

@media only screen and (max-width: 1200px) {
    .main {
        padding: 80px 40px;
    }
}
@media only screen and (max-width: 768px) {
    .main {
        padding: 80px 30px;
    }
}

@media only screen and (max-width: 480px) {
    .text {
        text-align: justify;
    }
}