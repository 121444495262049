.container {
    width: "100%";

    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    margin-top: 30px;
}
.mainChild {
    flex: 3;
    margin: 1px;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.second img {
    width: 100%;
}
.first {
    align-items: end;
}
.mainChildContainer {
    display: flex;
    flex-direction: row;
    /* width: 275px; */
    align-items: center;
}
.mainChildTitle {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
    color: #121212;
    text-shadow: 0px 0px 250px #121212;
    margin: 0 0 10px;
}
.mainChildDescription {
    font-family: 'GothamPro';
    font-size: 13px;
    line-height: 105%;
    color: #121212;
    text-shadow: 0px 0px 250px #121212;
    margin: 0;
    width: 240px;
}
.mainChildIcon {
   margin-right: 20px;
}
.mainChildContainer2 {
    margin-right: 50px;
    margin-top: 37px;
}
.mainChildContainer4 {
    margin-left: 50px;
    margin-top: 37px;
}
.showMore {
    align-self: center;
    margin: 20px 0 60px;
    text-decoration: none;
}
.showMore span {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.05em;
    margin-right: 10px;
    color: #9B2743;
    text-decoration: none;
}