.container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.8);
    z-index: 999999;
    
}
.main {
    width: 500px;
    background-color: #fff;
    padding: 25px 25px 30px;
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(155, 39, 67, 1);
}
.title {
    font-family: GothamPro;
    font-size: 25px;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
}

.modalInput {
    display: inline-flex;
    flex: 1;
    box-sizing: border-box;
    width: 400px;
    height: 46px;
    margin-top: 20px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    color: #121212;
    border: 1px solid rgba(155, 39, 67, 1);
    outline: none;
    font-family: GothamPro;
}
.modalBtn {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 450px;
    height: 46px;
    margin-top: 20px;
    background-color: #121212;
}
.modalBtn span {
    font-family: GothamPro;
    font-size: 16px;
    line-height: 1.33;
    color: #fff;
    font-weight: 700;
}

@media only screen and (max-width: 600px) {
    .main {
        width: 90%;
    }
    .modalInput {
        width: 100%;
    }
    .modalBtn {
        width: 100%;
    }
}
@media only screen and (max-width: 480px) {
    .main {
        width: 90%;
    }
    .modalInput {
        width: 100%;
    }
    .modalBtn {
        width: 100%;
    }
}