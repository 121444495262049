.container {
    width: "100%";
    /* height: 700px; */
    margin: 50px 0 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
} 
.subTitle {   
    font-family: "GothamPro";
    font-weight: 500;
    font-size: 36px;
    line-height: 34px;
    letter-spacing: -0.025em;
    text-decoration-line: underline;
    color: #9B2743;
    width: 95%;
    display: flex;
    align-self: center;
}
.main {
    display: flex;
    flex: 1;
    position: relative;
}
.arrowBtn {
    position: absolute;
    z-index: 9;
    right: 10px;
    top: 50%;
    background-color: rgba(256,256,256,0.8);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px  rgba(58, 67, 78, 0.3);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .subTitle {   
        font-size: 32px;
        line-height: 30px;
    }
}
@media only screen and (max-width: 1024px) {
    .subTitle {   
        font-size: 28px;
        line-height: 28px; 
        margin: 0;
    }
    .arrowBtn {
        width: 50px;
        height: 50px;
    }
    .arrowBtn svg {
        width: 18px;
        height: 18px;
    }
}
@media only screen and (max-width: 768px) {
    .subTitle {   
        font-size: 24px;
        line-height: 24px; 
        margin: 0;
    }
    .arrowBtn {
        width: 50px;
        height: 50px;
    }
    .arrowBtn svg {
        width: 18px;
        height: 18px;
    }
}
@media only screen and (max-width: 480px) {
    .subTitle {   
        font-size: 18px;
        line-height: 18px; 
        margin: 0;
    }
    .arrowBtn {
        width: 50px;
        height: 50px;
    }
    .arrowBtn svg {
        width: 18px;
        height: 18px;
    }
}