
.main {
    background-color: #fff; 
    padding: 120px 60px 60px;
}
.catalogContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
}
.sliderContainer {
    width: 600px;
}
.rightContainer {
    width: 500px;
    padding-left: 60px;
}
.title {
    font-family: "DinBold";
    font-size: 40px;
    color: #121212;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 400;
}
.price {
    font-family: "DinBold";
    font-size: 30px;
    color: #121212;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 400;
}
.selectDot {
    border: rgba(58, 67, 78, 0.9) solid 1px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}
.activeDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(58, 67, 78, 0.9);
}
.sizeContainer {
    display: flex;
    flex-direction: column;
}
.sizeTitle {
    color: rgba(58, 67, 78, 0.9);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 17px;
    display: block;
    margin-bottom: 8px;
}
.sizeRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:  4px 0;
    cursor: pointer;
}

.size {
    color: rgba(58, 67, 78, 0.8);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 14px;
}
.colorContainer {
    display: flex;
    flex-direction: column;
}
.colorTitle {
    color: rgba(58, 67, 78, 0.9);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 17px;
    display: block;
    margin-top: 16px;
    margin-bottom: 8px;
}
.colorRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin:  4px 0;
    cursor: pointer;
}
.colorImg {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-right: 5px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
}
.color {
    color: rgba(58, 67, 78, 0.8);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 14px;
}
.description {
    color: rgba(58, 67, 78, 0.9);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-top: 30px;
}
.characterizations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.characterizationsTitle {
    font-family: "DinBold";
    font-size: 40px;
    color: #121212;
    margin: 0;
    margin-bottom: 15px;
    font-weight: 400;
}
.characterizationsMain {
    display: flex;
    flex-direction: row;
    width: 90%;
}
.characterizationsPart {
    display: flex;
    flex-direction: column;
    height: 80px;
    flex: 0.2;
    justify-content: space-around;
    padding: 0 20px;
}
.characterizationsKey {
    color: rgba(58, 67, 78, 0.9);
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 14px;
}
.characterizationsValue {
    color: #121212;
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 15px;
}
.characterizationsLine {
    height: 80px;
    width: 0.1px;
    background-color: #121212;
}
@media only screen and (max-width: 1010px) {
    .characterizationsMain {
        width: 100%;
    }
}
@media only screen and (max-width: 920px) {
    .characterizationsMain {
        flex-wrap: wrap;
        justify-content: center;
    }
    .characterizationsPart {
        flex: 0.3;
        min-width: 23%;
        margin-top: 20px;
    }
    .hideLine {
        display: none;
    }
}
@media only screen and (max-width: 610px) {
    .main {
        padding: 100px 30px 60px;
    }
    .characterizationsMain {
        flex-wrap: wrap;
        justify-content: center;
    }
    .characterizationsPart {
        flex: 0.5;
        min-width: 33%;
        margin-top: 20px;
    }
    .hideLine {
        display: flex;
    }
    .hideLine2 {
        display: none;
    }
}