.container {
    width: "100%";
    height: 320px;
    background-color: #121212;
    padding: 40px 100px 0;
    display: flex;
    flex: 1;
    position: relative;
}
.logo svg {
    width: 35px;
    height: 41px;
}
.section {
    flex: 0.2;
    height: 200px
}
.lastSection {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
.logoText {
    font-family: 'DinBold';
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    padding-top: 15px;
    margin-left: 4px;
    color:#fff;
    padding-bottom: 15px;
    position: relative;
    bottom: 3.5px;
}
.title {
    font-family: 'GothamPro';
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #FFFFFF;
}
.footerText {
    font-family: 'GothamPro';
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    cursor: pointer;
    display: block;
    text-decoration-line: none;
    margin: 14px 0;
}
.telContainer { 
    display: flex; 
    flex-direction: row;
}
.telContainer .footerText {
    margin: 0;
    margin-right: 5px;
}
.logo div {
    display: none;
}
.YandexMetrika {
    position: absolute;
    bottom: 10px;
    left: 40px;
}
@media only screen and (max-width: 1400px) {
    .container {
        width: "100%";
        height: 320px;
        background-color: #121212;
        padding: 40px 40px 0;
        display: flex;
        flex: 1;
    }
}
@media only screen and (max-width: 1200px) {
    .lastSection {
        display: none;
    }
    .section {
        flex: 0.3;
    }
    .logo div {
        display: flex;
    }
}
@media only screen and (max-width: 1024px) {
    .container {
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        padding: 40px;
    }
    .section {
        flex: 0.5;
        min-width: 50%;
        box-sizing: border-box;
        padding-right: 10%;
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 768px) {
    .container {
        padding: 40px 10px 40px 40px;
    }
    .section {
        padding-right: 5%;
    }
}
@media only screen and (max-width: 560px) {
    .container {
        flex-direction: column;
        flex-wrap: nowrap;
        height: auto;
        padding: 40px;
    }
    .section {
        flex: 1;
        min-width: 0%;
        max-width: 80%;
        box-sizing: border-box;
        margin-bottom: 30px;
        padding-left: 10%;
    }
    .YandexMetrika {
        left: 17%;
    }
}
@media only screen and (max-width: 475px) {

    .section {
        max-width: 100%;
    }
}