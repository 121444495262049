/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

@font-face {
    font-family: 'GothamPro';
    src: local('GothamPro'), url(../../assets/fonts/GothamPro/gothampro.ttf);
}
@font-face {
    font-family: 'GothamProBold';
    src: local('GothamPro'), url(../../assets/fonts/GothamPro/gothampro_bold.ttf);
}
@font-face {
    font-family: 'GothamProBlack';
    src: local('GothamPro'), url(../../assets/fonts/GothamPro/gothampro_black.ttf);
}
@font-face {
font-family: 'GothamProMedium';
src: local('GothamPro'), url(../../assets/fonts/GothamPro/gothampro_medium.ttf);
}
@font-face {
font-family: 'GothamProLight';
src: local('GothamPro'), url(../../assets/fonts/GothamPro/gothampro_light.ttf);
}
@font-face {
font-family: 'DinBold';
src: local('DIN Condensed Bold'), url(../../assets/fonts/DinCondensed/DINCondensedBold.ttf) ;
}
:root {
    --color-bordo: rgba(155, 39, 67, 1);
    --color-gray: "rgba(58, 67, 78, 1)";
    --color-black: "rgba(18, 18, 18, 1)";
    --color-white: "rgba(255, 255, 255, 1)";
}
.container {
    background-color: #fff;
    height: 70px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-shadow: 0px 0px 60px rgba(65, 65, 65, 0.6);
            box-shadow: 0px 0px 60px rgba(65, 65, 65, 0.6);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: absolute;
    z-index: 999;
    -webkit-transform: translate();
        -ms-transform: translate();
            transform: translate();

}
.logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-decoration: none;
    color: var(--color-black);
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.logo svg {
    width: 35px;
    height: 41px;
}
.logoText {
    font-family: 'DinBold';

    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.02em;
    padding-top: 15px;
    margin-left: 4px;
    color:#121212;
    position: relative;
    bottom: 3.5px;
}
.menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.menuItem {
    text-transform: uppercase;
    text-decoration: none;
    font-family: "GothamPro";
    font-size: 16px;
    line-height: 15px;
    letter-spacing: -0.05em;
    color: var(--color-black);
    margin: 0 14px;
}

.menuItem .line {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1px;
    width: 0%;
    background-color: var(--color-bordo);
    margin: 0 auto;
    -webkit-transition: width 200ms;
    -o-transition: width 200ms;
    transition: width 200ms;
}
.menuItem:hover .line {
    width: 80%;
}
.menuLi {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.activeMenu  .line {
    width: 80%;
}
.headerRight {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.phoneItem {
    text-decoration: none;
    font-family: 'GothamPro';
    font-size: 14px;
    line-height: 13px;
    letter-spacing: -0.055em;
    color: var(--color-black);
    margin-left: 21px;
}
.phoneContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.menuLi:hover .dropDown {
    max-height: 1000px;
    opacity: 1;
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    border:  1px solid var(--color-bordo);
}
.dropDown {
    position: absolute;
    top: 70px;
    background-color: #fff;
    z-index: 99;
    margin: 0;
    padding: 0;
    height: auto;
    overflow: hidden;
    max-height: 0;
    border:  0;
    opacity: 1;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.5s, border 0s;
    -o-transition: all 0.5s, border 0s;
    transition: all 0.5s, border 0s;
    -webkit-box-shadow: -14px 15px 30px 1px rgba(0,0,0,0.31);
            box-shadow: -14px 15px 30px 1px rgba(0,0,0,0.31);
}
.dropDown .menuItem {
    margin: 20px ;
    text-align: start;
}

@media only screen and (max-width: 1440px) {
    .container {
        padding: 0 40px;
    }
    .phoneItem {
        margin-left: 10px;
    }
    .logo svg {
        width: 30px;
        height: 36px;
    }
    .logoText {
        font-size: 28px;
        padding-top: 20px;
    }
    .menuItem {
        font-size: 14px;
        margin: 0 10px;
    }
    .phoneItem {
        font-size: 14px;
        line-height: 14px;
        margin-left: 11px;
    }
}