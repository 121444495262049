
.main {
    background-color: #fff; 
    padding: 140px 100px;
}
.subTitle {
    font-family: 'Inter';
    font-weight: 500;
    font-size: 18px;
    color: #121212;
    margin: 0;
    margin-bottom: 10px;
}
.img {
    object-fit: contain;
    margin: 15px;
    display: flex;
    width: 30%;
}
.description {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    color: #121212;
    min-height: 18px;
    margin: 0;
}
.row-flex-end {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: flex-end; 
    flex-wrap: wrap;
}
.row-flex-center {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.dostavka {
    margin-top: 70px;
    display: flex;
    flex-direction: row;
}
.dostavka .column {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-right: 30px;
}
.dostavka .column .img {
    width: 100%;
}
.dostavka .img {
    width: 60%;
    object-fit: cover;
}
@media only screen and (max-width: 1200px) {
    .main {
        padding: 100px 60px;
    }
}
@media only screen and (max-width: 768px) {
    .main {
        padding: 80px 30px;
    }
    .img {
        object-fit: contain;
        margin: 5px;
        display: flex;
        width: 30%;
    }
    .dostavka .column {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin-right: 15px; 
    }
    .row-flex-end, .row-flex-center {
        justify-content: space-between;
    }
}
@media only screen and (max-width: 550px) {
    .row-flex-end, .row-flex-center {
        flex-direction: column;
        align-items: center;
    }
    .img {
        width: 100%;
        margin: 10px;
    }
    .dostavka {
        flex-direction: column;
    }
    .dostavka .column, .dostavka .img {
        width: 100%;
    }
    
}