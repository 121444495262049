
.main {
    background-color: #fff; 
    padding-top: 100px;
}
.catalogContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    padding: 10px 30px;
}