
.main {
    z-index: 999999999;
    margin: 0px auto;
    position: fixed;
    display: flex;
    top: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: -1px 3px 25px 1px rgba(0,0,0,0.78);
    -webkit-box-shadow: -1px 3px 25px 1px rgba(0,0,0,0.78);
    -moz-box-shadow: -1px 3px 25px 1px rgba(0,0,0,0.78);
    justify-content: center;
    align-items: center;
    right: 0;
    left: 0;
    width: 300px;
}
.img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
.text {
    font-family: GothamPro;
    font-size: 20px;
    margin-top: 3px
}