.title {
    font-family: "DinBold";
    font-size: 60px;
    line-height: 120%;
    text-align: center;
    color: #121212;
    margin: 23px auto;
    align-self: center;
    font-weight: 400;
    text-transform: uppercase;
    max-width: 90%;
}
@media only screen and (max-width: 990px) {
    .title {
        font-size: 50px;
        
    }
}
@media only screen and (max-width: 768px) {
    .title {
        font-size: 40px;
    }
}
@media only screen and (max-width: 550px) {
    .title {
        max-width: 100%;
        padding: 0 20px;
    }
}
@media only screen and (max-width: 480px) {
    .title {
        font-size: 35px;
    }
}