.container {
    width: "100%";
    padding: 0px 0 0 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}
.img {
    height: 400px;
    object-fit: contain;
    margin: 0 10px;
}

.showMore {
    margin-bottom: 21px;
    padding: 20px 0;
    text-decoration: none;
}
.showMore span {
    font-family: 'GothamPro';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.05em;
    margin-right: 10px;
    color: #9B2743;
    text-decoration: none;
}
@media only screen and (max-width: 1024px) {
    .container {
        padding: 0px 0 0 50px;
    }
}
@media only screen and (max-width: 550px) {
    .container {
        padding: 0px 0 0 10px;
    }
}